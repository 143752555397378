<template>
    <Toast :baseZIndex="1" />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <DataTable :value="userslist" :lazy="true" :paginator="true" :rows="10" :totalRecords="totalRecords"
                    :loading="loading" @page="onPage($event)" class="p-datatable-users" data-key="mav1" :rowHover="true"
                    v-model:expandedRows="expandedRows"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    :style="{ 'overflow-wrap': 'break-word' }">
                    <Toolbar>
                        <template v-slot:left>
                            <h5 class="p-m-0">Users </h5>
                        </template>
                        <template v-slot:right>
                            <Button label="Add User" icon="pi pi-plus" class=" p-mr-2"
                                @click="addSuperAdminUsersDialogOpen" />
                        </template>
                    </Toolbar>
                    <template #empty> No data found. </template>
                    <template #loading> Loading data Please wait... </template>
                    <Column header="User Name" headerStyle="width: 40%" class="p-text-capitalize">
                        <template #body="{ data }">
                            <!-- <div class="p-text-capitalize">{{ mav8 || 'N/A' }}</div> -->
                            <div class="p-d-flex p-ai-center">
                                <div class="p-mr-3">
                                    <span v-if="data.mav16 != null && data.mav16 != ''">
                                        <img :src="data.mav16" width="50" height="50"
                                            style="vertical-align: middle; border-radius: 75px" />
                                    </span>
                                    <span v-else>
                                        <img src="/assets/layout/images/widgets/asset-profile-11.png" width="50" height="50"
                                            style="vertical-align: middle; border-radius: 75px" />
                                    </span>
                                </div>
                                <div>
                                    <div class="p-text-capitalize overflow-span">{{ data.mav8 ? data.mav8
                                        : 'N/A' }}</div>
                                </div>
                            </div>
                        </template>
                    </Column>
                    <Column header="Contact Details" headerStyle="width: 20%">
                        <template #body="{ data: { mav9, mav10 } }">
                            <div>
                                <div>{{ mav9 ? `+91 ${mav9}` : 'N/A' }}</div>
                                <div class="p-mt-1"><small class="muted-text overflow-span">{{ mav10 || 'N/A' }}</small>
                                </div>
                            </div>
                        </template>
                    </Column>
                    <Column header="Status" headerStyle="width: 20%">
                        <template #body="{ data: { mav15 } }">
                            <span v-if="mav15 == 1" :class="'user-badge status-active'">Active</span>
                            <span v-if="mav15 == 2" :class="'user-badge status-temp-suspend'">Temporarily Suspended</span>
                            <span v-if="mav15 == 3" :class="'user-badge status-per-suspend'">Permanently Suspended</span>
                        </template>
                    </Column>
                    <Column header="Action" headerStyle="width: 15%">
                        <template #body="{ data }">
                            <Button icon="pi pi-pencil" class="p-button-rounded "
                                @click="editSuperAdminUserDialogOpen(data)" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
    <!-- add super admin user dialog start here -->
    <Dialog v-model:visible="addUserStatus" :style="{ width: '1000px' }" header="Add User" :modal="true" class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-3">
                    <label for="title">
                        Title
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="title" v-model="title" :options="titleList" optionLabel="label" placeholder="Select"
                        appendTo="body">
                    </Dropdown>
                    <small class="p-invalid p-error" v-if="v$.title.$error">{{ v$.title.required.$message }}</small>
                </div>
                <div class="p-field p-col-12 p-md-3">
                    <label for="userfirstname">
                        First Name
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="userfirstname" class="p-text-capitalize" v-model.trim="userfirstname" required="true"
                        maxlength="30" autofocus :class="{ 'p-invalid': submitted && !userfirstname }" />
                    <small class="p-invalid p-error" v-if="v$.userfirstname.$error">{{
                        v$.userfirstname.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-3">
                    <label for="usermiddlename">
                        Middle Name
                    </label>
                    <InputText id="usermiddlename" class="p-text-capitalize" v-model.trim="usermiddlename" maxlength="30"
                        autofocus :class="{ 'p-invalid': submitted && !usermiddlename }" />
                </div>
                <div class="p-field p-col-12 p-md-3">
                    <label for="userlastname">
                        Last Name
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="userlastname" class="p-text-capitalize" v-model.trim="userlastname" required="true"
                        maxlength="30" autofocus :class="{ 'p-invalid': submitted && !userlastname }" />
                    <small class="p-invalid p-error" v-if="v$.userlastname.$error">{{
                        v$.userlastname.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="usermobile">
                        Mobile No.
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            +91
                        </span>
                        <InputText id="usermobile" v-model="usermobile" autocomplete="off" maxlength="10"
                            @keypress="onlyNumber" autofocus :class="{ 'p-invalid': submitted && !usermobile }" />
                    </div>
                    <small class="p-invalid p-error" v-if="v$.usermobile.$error">{{
                        v$.usermobile.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="user-email">
                        Email
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="user-email" v-model.trim="userEmail" required="true" autocomplete="usremail"
                        maxlength="70" autofocus :class="{ 'p-invalid': submitted && !userEmail }" />
                    <small class="p-invalid p-error" v-if="v$.userEmail.$error">{{
                        v$.userEmail.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label>Status <span class="p-invalid p-error"> *</span></label>
                    <div class="p-field-radiobutton">
                        <RadioButton id="active_type" name="option" :value="1" v-model="statustype" />
                        <label for="active_type" class="p-mr-2">Active</label>
                        <RadioButton id="temp_suspend_type" name="option" :value="2" v-model="statustype" />
                        <label for="temp_suspend_type" class="p-mr-2">Temporarily Suspended</label>
                        <RadioButton id="per_suspend_type" name="option" :value="3" v-model="statustype" />
                        <label for="per_suspend_type" class="p-mr-2">Permanently Suspended</label>
                    </div>
                    <small class="p-invalid p-error" v-if="v$.statustype.$error">{{
                        v$.statustype.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label>Upload Profile</label>
                    <FileUpload mode="basic" ref="profileImage" accept="image/*" :maxFileSize="1000000"
                        chooseLabel="Upload Profile" auto @select="onSelectProfile">
                    </FileUpload>
                    <img v-if="profileUrl" class="p-pt-2" role="presentation" :alt="profileUrl.name" :src="profileUrl.url"
                        width="120" height="120" />
                    <!-- <small class="p-invalid p-error" v-if="v$.profileImage.$error">{{
                        v$.profileImage.$errors[0].$message
                    }}</small> -->
                    <small class="p-invalid p-error" v-if="profileImageError">{{ profileImageError }}</small>
                </div>
            </div>
        </div>
        <template #footer>
            <div>
                <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                    @click="addNewSuperAdminUser" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
        </template>
    </Dialog>
    <!-- add super admin user dialog end here -->
    <!-- edit super admin user dialog start here -->
    <Dialog v-model:visible="editUserStatus" :style="{ width: '1000px' }" header="Edit User" :modal="true" class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-3">
                    <label for="title">
                        Title
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="title" v-model="title" :options="titleList" optionLabel="label" placeholder="Select"
                        appendTo="body">
                    </Dropdown>
                    <small class="p-invalid p-error" v-if="v$.title.$error">{{ v$.title.required.$message }}</small>
                </div>
                <div class="p-field p-col-12 p-md-3">
                    <label for="userfirstname">
                        First Name
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="userfirstname" class="p-text-capitalize" v-model.trim="userfirstname" required="true"
                        maxlength="30" autofocus :class="{ 'p-invalid': submitted && !userfirstname }" />
                    <small class="p-invalid p-error" v-if="v$.userfirstname.$error">{{
                        v$.userfirstname.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-3">
                    <label for="usermiddlename">
                        Middle Name</label>
                    <InputText id="usermiddlename" class="p-text-capitalize" v-model.trim="usermiddlename" maxlength="30"
                        autofocus :class="{ 'p-invalid': submitted && !usermiddlename }" />
                </div>
                <div class="p-field p-col-12 p-md-3">
                    <label for="userlastname">
                        Last Name
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="userlastname" class="p-text-capitalize" v-model.trim="userlastname" required="true"
                        maxlength="30" autofocus :class="{ 'p-invalid': submitted && !userlastname }" />
                    <small class="p-invalid p-error" v-if="v$.userlastname.$error">{{
                        v$.userlastname.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="usermobile">
                        Mobile No.
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            +91
                        </span>
                        <InputText id="usermobile" v-model="usermobile" autocomplete="off" maxlength="10"
                            @keypress="onlyNumber" autofocus :class="{ 'p-invalid': submitted && !usermobile }" />
                    </div>
                    <small class="p-invalid p-error" v-if="v$.usermobile.$error">{{
                        v$.usermobile.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="user-email">
                        Email
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="user-email" v-model.trim="userEmail" required="true" autocomplete="user-email"
                        maxlength="70" autofocus :class="{ 'p-invalid': submitted && !userEmail }" />
                    <small class="p-invalid p-error" v-if="v$.userEmail.$error">{{
                        v$.userEmail.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="user-password">
                        Password
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <!-- <InputText id="user-password" v-model.trim="userPassword" toggleMask required="true" autocomplete="user-password"
                        maxlength="12" autofocus :class="{ 'p-invalid': submitted && !userPassword }" /> -->
                    <Password id="password" v-model="userPassword" toggleMask autofocus maxLength="20"
                        :class="{ 'p-invalid': submitted && !userPassword }" />
                    <small class="p-invalid p-error" v-if="v$.userPassword.$error">{{
                        v$.userPassword.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label>Status <span class="p-invalid p-error"> *</span></label>
                    <div class="p-field-radiobutton">
                        <RadioButton id="active_type" name="option" v-bind:value="1" v-model="statustype"
                            :checked="statustype == 1" />
                        <label for="active_type" class="p-mr-2">Active</label>
                        <RadioButton id="temp_suspend_type" name="option" v-bind:value="2" v-model="statustype"
                            :checked="statustype == 2" />
                        <label for="temp_suspend_type" class="p-mr-2">Temporarily Suspended</label>
                        <RadioButton id="per_suspend_type" name="option" v-bind:value="3" v-model="statustype"
                            :checked="statustype == 3" />
                        <label for="per_suspend_type" class="p-mr-2">Permanently Suspended</label>
                    </div>
                    <small class="p-invalid p-error" v-if="v$.statustype.$error">{{
                        v$.statustype.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label>Upload Profile </label>
                    <FileUpload mode="basic" ref="profileImage" accept="image/*" :maxFileSize="1000000"
                        chooseLabel="Upload Profile" auto @select="onSelectProfile">
                    </FileUpload>
                    <span v-if="profileUrl == null">
                        <img v-if="userprofilefile" class="p-pt-2" role="presentation" alt="userprofile87878"
                            :src="userprofilefile" width="120" height="120" />
                    </span>
                    <img v-if="profileUrl" class="p-pt-2" role="presentation" :alt="profileUrl.name" :src="profileUrl.url"
                        width="120" height="120" />
                    <!-- <small class="p-invalid p-error" v-if="v$.profileImage.$error">{{
                        v$.profileImage.$errors[0].$message
                    }}</small> -->
                    <small class="p-invalid p-error" v-if="profileImageError">{{ profileImageError }}</small>
                </div>
            </div>
        </div>
        <template #footer>
            <div>
                <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                    @click="updateSuperAdminUser" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
        </template>
    </Dialog>
    <!-- edit super admin user dialog end here -->
</template>
<script>
import ApiService from '../service/ApiService';
import useValidate from '@vuelidate/core';
import { required, alpha, helpers, minLength, maxLength, numeric, email } from '@vuelidate/validators';
export default {
    data() {
        return {
            v$: useValidate(),
            userslist: [],
            page_no: 0,
            totalRecords: 0,
            loading: false,
            addUserStatus: false,
            editUserStatus: false,
            userfullname: '',
            usershortname: '',
            title: '',
            titleList: [],
            userfirstname: '',
            usermiddlename: '',
            userlastname: '',
            usermobile: '',
            userEmail: '',
            statustype: '',
            showLoader: false,
            submitted: false,
            profileUrl: null,
            profileImage: null,
            profileImageError: '',
            userId: '',
            userprofilefile: null,
            userPassword: ''
        };
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    async mounted() {
        this.loading = true;
        ({ data: this.titleList } = await this.ApiService.getTitles());
        this.superAdminUserList();
    },
    validations() {
        let validation = {
            title: { required: helpers.withMessage('Please select title', required) },
            userfirstname: { required: helpers.withMessage('Please enter first name', required), alpha: helpers.withMessage('Please input alphabet characters only', alpha) },
            userlastname: { required: helpers.withMessage('Please enter last name', required), alpha: helpers.withMessage('Please input alphabet characters only', alpha) },
            usermobile: { required: helpers.withMessage('Please enter mobile number', required), numeric: helpers.withMessage('Only numbers are allowed', numeric), minLength: minLength(10), maxLength: maxLength(10) },
            userEmail: { required: helpers.withMessage('Please enter email id', required), email: helpers.withMessage('Email id is invalid', email) },
            statustype: { required: helpers.withMessage('Please select status', required) },
            userPassword: { required: helpers.withMessage('Please enter password', required), minLength: minLength(8), maxLength: maxLength(20) },
        };
        return validation;
    },
    methods: {
        async onSelectProfile(e) {
            this.profileImage = e.files[0];
            const file = e.files[0];
            let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!file.size > 2048) {
                this.profileImageError = 'File size cannot exceed more than 2MB';
                this.profileImage = '';
            } else if (!file.name.match(allowedExtensions)) {
                this.profileImageError = 'Invalid file type';
                this.profileImage = '';
            } else {
                this.profileImageError = '';
                if (file && file.type.includes('image/')) {
                    this.profileUrl = { url: file.objectURL, name: file.name };
                    this.profileImage = e.files[0];
                } else {
                    this.profileImageError = 'Invalid file type';
                    this.profileImage = '';
                }
            }
        },

        superAdminUserList(ev) {
            this.loading = true;
            this.ApiService.getSuperAdminUserList(ev).then(({ count, data, success }) => {
                if (success) {
                    this.userslist = data;
                    this.totalRecords = count;
                } else {
                    this.userslist = [];
                }
            }).catch(({ message }) => {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
            }).finally(() => {
                this.loading = false;
            });
        },

        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.superAdminUserList({ page_no: event.page });
        },

        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },

        addSuperAdminUsersDialogOpen() {
            this.submitted = false;
            this.addUserStatus = true;
            this.v$.$reset();
            this.userfirstname = "";
            this.usermiddlename = "";
            this.userlastname = "";
            this.usershortname = "";
            this.userfullname = "";
            this.usermobile = "";
            this.userEmail = "";
            this.statustype = 1;
            this.title = {};
            this.profileUrl = null;
            this.profileImage = null;
        },

        editSuperAdminUserDialogOpen(data) {
            this.submitted = false;
            this.editUserStatus = true;
            this.userprofilefile = null;
            ({
                mav1: this.userId,
                mav4: this.userfirstname,
                mav5: this.usermiddlename,
                mav6: this.userlastname,
                mav7: this.usershortname,
                mav8: this.userfullname,
                mav9: this.usermobile,
                mav10: this.userEmail,
                mav15: this.statustype,
                mav14: this.userPassword,
            } = data);
            this.title = { label: data.mav3, value: data.mav2 };
            if (data.mav16 != null) {
                this.userprofilefile = data.mav16;
            }
        },

        addNewSuperAdminUser() {
            this.submitted = true;
            this.errormsg = '';
            this.v$.$validate();
            let formData = new FormData();
            formData.append('mav17', this.profileImage)
            formData.append('mav2', this.title.value);
            formData.append('mav3', this.title.label);
            formData.append('mav4', this.userfirstname);
            if (this.usermiddlename) {
                formData.append('mav5', this.usermiddlename);
            }
            formData.append('mav6', this.userlastname);
            formData.append('mav9', this.usermobile);
            formData.append('mav10', this.userEmail);
            formData.append('mav15', this.statustype);

            if (!this.v$.$error) {
                this.showLoader = true;
                this.ApiService.createUpdateSuperAdminUser(formData).then((items) => {
                    if (items.success) {
                        this.showLoader = false;
                        var successMsg = items.message;
                        this.addUserStatus = false;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.superAdminUserList();
                    } else {
                        this.showLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },

        updateSuperAdminUser() {
            this.submitted = true;
            this.errormsg = '';
            this.v$.$validate();
            let formData = new FormData();
            if (this.profileImage) {
                formData.append('mav17', this.profileImage);
            }
            this.userId && formData.append('mav1', this.userId);
            this.title.value && formData.append('mav2', this.title.value);
            this.title.label && formData.append('mav3', this.title.label);
            this.userfirstname && formData.append('mav4', this.userfirstname);
            this.usermiddlename && formData.append('mav5', this.usermiddlename);
            this.userlastname && formData.append('mav6', this.userlastname);
            this.usershortname && formData.append('mav7', this.usershortname);
            this.userfullname && formData.append('mav8', this.userfullname);
            this.usermobile && formData.append('mav9', this.usermobile);
            this.userEmail && formData.append('mav10', this.userEmail);
            this.userPassword && formData.append('mav14', this.userPassword);
            this.statustype && formData.append('mav15', this.statustype);
            if (!this.v$.$error) {
                this.showLoader = true
                this.ApiService.createUpdateSuperAdminUser(formData).then((items) => {
                    if (items.status == 200) {
                        this.showLoader = false;
                        var successMsg = items.message;
                        this.editUserStatus = false;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.userprofilefile = null;
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.superAdminUserList();
                    } else {
                        this.showLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
    },
};
</script>
<style scoped lang="scss">
.overflow-span {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    /* Optionally set a maximum width */
}

.user-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-active {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-per-suspend {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-temp-suspend {
        background: #ffd8b2;
        color: #805b36;
    }
}
</style>
